import { ThemeProvider } from '@material-ui/core/styles'
import ActivityStage from 'components/activity-plans/stage'
import { SnackbarProvider } from 'notistack'
import ABTestPage from 'pages/ab'
import AcceptInvitation from 'pages/accept-invitation'
import AssumeUserPage from 'pages/assume-user'
import BookingConfirmationPage from 'pages/booking-confirmation'
import LogoutPage from 'pages/logout'
import LoggedOutPage from 'pages/logged-out'
import SinglePlansPublicPage from 'pages/pga-coach/activity-plans/stage/activity-plans/SingleActivityPlanPublic'
import CreateEvent from 'pages/pga-coach/bookings/events/new'
import CreateOnboardingEvent from 'pages/pga-coach/bookings/events/new/onboarding'
import CreateProfile from 'pages/pga-coach/profile/new'
import CreateProfileOnboarding from 'pages/pga-coach/profile/new/onboarding'
import PublicActivityPlansPage from 'pages/public-activity-plans'
import ScheduleSessionFlow from 'pages/schedule'
import CoachLeads from 'pages/coach-leads'
import WelcomePage from 'pages/welcome'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import FamilyCupCaptainRoutes from 'routes/family-cup-captain-routes'
import FamilyCupInvitationRoutes from 'routes/family-cup-invitation-routes'
import FamilyCupMarketingRoutes from 'routes/family-cup-marketing-routes'
import FamilyCupRegistrationRoutes from 'routes/family-cup-registration-routes'
import JuniorLeagueMarketingRoutes from 'routes/junior-league-marketing-routes'
import JuniorLeagueRegistrationRoutes from 'routes/junior-league-registration-routes'
import JuniorLeagueRoutes from 'routes/junior-league-routes'
import JuniorLeagueSignupRoutes from 'routes/junior-league-signup-routes'
import ThingsToDoRoutes from 'routes/things-to-do-routes'
import themes, { extendTheme } from '../themes'
import AcademyRoutes from './academy-routes'
import AccountRoutes from './account-routes'
import AdminRoutes from './admin-routes'
import ClubRoutes from './club-routes'
import FamilyCupRoutes from './family-cup-routes'
import FamilyCupSignupRoutes from './fc-signup-routes'
import JuniorLeagueManagerRoutes from './junior-league-manager-routes'
import PgaCoachRoutes from './pga-coach-routes'
import Ideas from '../pages/ideas'
import WaiverPage from '../pages/waiver'
import ExternalRedirect from 'components/external-redirect'
import PaymentRequestPage from '../pages/payment-request'
import AttendeeRsvpPage from 'pages/attendee-rsvp'
import CoachBusinessInsightsDashboard from 'pages/coach-business-insights'

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/coaching-center">
          <AcademyRoutes />
        </Route>
        <Route path="/pga-coaching-center-at-pga-frisco">
          <AcademyRoutes />
        </Route>
        <Route path={'/family-golf'}>
          <FamilyCupRoutes />
        </Route>
        <Route path={'/family-golf-captain'}>
          <FamilyCupCaptainRoutes />
        </Route>
        <Route
          exact
          path="/pga-coach/bookings/events/new"
          component={CreateEvent}
        />
        <Route
          exact
          path="/pga-coach/onboarding/bookings/events/new"
          component={() => <CreateOnboardingEvent />}
        />
        <Route exact path="/pga-coach/profile/new" component={CreateProfile} />
        <Route
          exact
          path="/pga-coach/onboarding/profile/new"
          component={CreateProfileOnboarding}
        />
        <Route exact path="/pga-coach/app">
          <ExternalRedirect
            externalUrl="https://onelink.to/pga-coach"
            destination="download PGA Coach App"
          />
        </Route>
        <Route path="/pga-coach">
          <PgaCoachRoutes />
        </Route>
        <Route exact path="/club/app">
          <ExternalRedirect
            externalUrl="https://onelink.to/mypga"
            destination="download MyPGA App"
          />
        </Route>
        <Route path="/club">
          <ClubRoutes />
        </Route>
        <Route path="/invitation/:connectionId" component={AcceptInvitation} />
        <Route
          path="/payment/:paymentRequestToken"
          component={PaymentRequestPage}
        />
        <Route exact path="/coach/:idOrSlug/schedule">
          <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
            <ScheduleSessionFlow />
          </ThemeProvider>
        </Route>
        <Route exact path="/coach/:idOrSlug/contact">
          <CoachLeads />
        </Route>
        <Route path={'/family-golf-signup'}>
          <FamilyCupSignupRoutes />
        </Route>
        {/* TODO: new path for Family Golf */}
        <Route path="/events">
          <FamilyCupMarketingRoutes />
        </Route>
        <Route path={'/family-golf-registration'}>
          <FamilyCupRegistrationRoutes />
        </Route>
        <Route path={'/family-golf-invitation'}>
          <FamilyCupInvitationRoutes />
        </Route>
        <Route path="/junior-league">
          <JuniorLeagueRoutes />
        </Route>
        <Route path="/junior-league-events">
          <JuniorLeagueMarketingRoutes />
        </Route>
        <Route path="/junior-league-signup">
          <JuniorLeagueSignupRoutes />
        </Route>
        <Route path="/junior-league-registration">
          <JuniorLeagueRegistrationRoutes />
        </Route>
        <Route path="/junior-league-manager">
          <JuniorLeagueManagerRoutes />
        </Route>
        <Route path="/things-to-do">
          <ThingsToDoRoutes />
        </Route>
        <Route exact path="/booking-confirmation/:confirmationCode">
          <ThemeProvider theme={extendTheme(themes.pgaCoach)}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <BookingConfirmationPage />
            </SnackbarProvider>
          </ThemeProvider>
        </Route>
        <Route path="/admin" component={AdminRoutes} />
        <Route path="/account" component={AccountRoutes} />
        <Route exact path="/" component={WelcomePage} />
        <Route exact path="/ab" component={ABTestPage} />
        <Route exact path="/assume-user" component={AssumeUserPage} />
        <Route exact path="/ideas" component={Ideas} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/logged-out" component={LoggedOutPage} />
        <Route path="/waivers/:waiverId?">
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <WaiverPage />
          </SnackbarProvider>
        </Route>
        <Route
          exact
          path="/activity-plan/:id"
          component={SinglePlansPublicPage}
        />
        <Route
          exact
          path="/activity-plans"
          component={PublicActivityPlansPage}
        />
        <Route path="/activity-plans/:stage" component={ActivityStage} />
        <Route
          path="/dues"
          exact
          component={() => {
            window.location.replace('/member/dues')
            return null
          }}
        />
        <Route path="/coach-business-insights">
          <CoachBusinessInsightsDashboard />
        </Route>
        <Route
          exact
          path="/event/:eventId/lesson/:lessonId/attendance/:contactId"
        >
          <ThemeProvider theme={extendTheme(themes.offeringsRefresh)}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <AttendeeRsvpPage />
            </SnackbarProvider>
          </ThemeProvider>
        </Route>
        <Route path="*" render={() => <div>Page Not Found</div>} />
      </Switch>
    </Router>
  )
}

export default Routes
