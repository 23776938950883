import React from 'react'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {
  ErrorMsg,
  CheckboxItemWrapper,
} from '../profile.styles'
import { findIndex, some } from 'lodash'
import { useFieldArray, useFormContext } from 'react-hook-form'

const ExperiencesQuery = gql`
  query Experiences {
    Experiences {
      message
      success
      experienceTypes {
        experienceTypeId
        typeName
        description
        experiences {
          experienceId
          experienceName
          experienceType
        }
      }
    }
  }
`

const isChecked = (experienceId, experiences) =>
  some(experiences, { experienceId })

const toggleExperienceChecked = (experienceId, experiences, push, remove) => {
  isChecked(experienceId, experiences)
    ? remove(findIndex(experiences, { experienceId }))
    : push({ experienceId })
}

const Experience = () => {
  const { register, errors, control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: 'experiences',
    control,
  })

  const { data, loading, error } = useQuery(ExperiencesQuery)

  if (error) {
    return <Error error={error} displayErrorType="page" />
  }
  if (loading) return <LinearProgress color="secondary" />

  const response = data && data.Experiences && data.Experiences.experienceTypes

  return response ? (
    <div key="profile-form-experience">
      {errors.experiences && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>
          {errors.experiences.message}
        </ErrorMsg>
      )}
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <input
              type="hidden"
              ref={register()}
              name={`experiences[${index}].experienceId`}
              value={item.experienceId}
            />
          </div>
        )
      })}
      <Typography variant="body2" color="textSecondary" gutterBottom>Choose any of these pre-populated options to help market your business and specialities!</Typography>
      {response.map(experienceType => {
        return (
          <Box key={experienceType.typeName} pt={3}>
            <Typography variant="subtitle1">
              {experienceType.typeName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {experienceType.description}
            </Typography>
            <Box>
              {experienceType.experiences.map(experience => {
                return (
                  <CheckboxItemWrapper key={experience.experienceId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={`exp-checkbox-${experienceType.typeName}-${experience.experienceId}`}
                          className="checkbox"
                          checked={isChecked(
                            experience.experienceId,
                            fields,
                          )}
                          onChange={() =>
                            toggleExperienceChecked(
                              experience.experienceId,
                              fields,
                              append,
                              remove,
                            )
                          }
                          value={experience.experienceId}
                          color="secondary"
                        />
                      }
                      label={experience.experienceName}
                      htmlFor={`exp-checkbox-${experienceType.typeName}-${experience.experienceId}`}
                    />
                  </CheckboxItemWrapper>
                )
              })}
            </Box>
          </Box>
        )
      })}
    </div>
  ) : (
    <Error />
  )
}

export default Experience
