import React from 'react'
import { formFields } from '../constants'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { isEmpty } from 'lodash'
import useStyles from '../profile.styles'
import FacilityVisibilityToggle from './facility-visibility-toggle'
import Chip from '@material-ui/core/Chip'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Tooltip from '@material-ui/core/Tooltip'
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import Box from '@material-ui/core/Box'
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined'
import { Link } from 'react-router-dom'
import { useIsMobile } from 'lib/hooks'
import SaveDialog from './dialog'
import { useFormContext } from 'react-hook-form'

const Facilities = ({ formFields, onSubmit }) => {
  const { formState, handleSubmit } = useFormContext()
  const { touched } = formState

  const FacilityAccordionSummary = withStyles({
    root: {
      flexDirection: 'row',
      paddingLeft: '24px',
      paddingRight: '8px',
      marginTop: '16px',
    },
    expandIcon: {
      marginRight: '0',
      marginLeft: '-12px',
    },
    content: {
      '&$expanded': {
        minHeight: '0',
      },
    },
  })(AccordionSummary)

  const facilities = formFields.facilities
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <div key="profile-form-facilities">
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.description}
      >
        Facilities added to your profile are displayed below. The primary
        facility is used for location-based searches on pga.com. To add, remove
        or modify the information, go to{' '}
        {!isEmpty(touched) ? (
          <SaveDialog
            trigger={<Link>Manage Facilities.</Link>}
            handleSubmit={handleSubmit(onSubmit)}
          />
        ) : (
          <Link to="/pga-coach/settings/facilities">Manage Facilities.</Link>
        )}
      </Typography>

      {!isEmpty(facilities) &&
        facilities.map((facility, index) => (
          <Accordion key={index}>
            <FacilityAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box className={classes.facilityWrapper}>
                <Box display="flex" alignItems="center">
                  <FacilityVisibilityToggle
                    visible={facility.customData?.visible}
                  />
                  <Box>
                    <Typography style={{ fontWeight: 500 }}>
                      {facility.facilityName}
                    </Typography>
                    {facility.customData?.facilityRank === 1 && isMobile && (
                      <Grid item>
                        <Tooltip
                          title="The primary facility is used for location-based searches and cannot be hidden or deleted."
                          placement="bottom"
                        >
                          <Chip
                            color="primary"
                            size="small"
                            className={classes.chip}
                            avatar={<StarBorderIcon />}
                            label="Primary"
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  </Box>
                </Box>
                {facility.customData?.facilityRank === 1 && !isMobile && (
                  <Grid item>
                    <Tooltip
                      title="The primary facility is used for location-based searches and cannot be hidden or deleted."
                      placement="bottom"
                    >
                      <Chip
                        color="primary"
                        className={classes.chip}
                        avatar={<StarBorderIcon />}
                        label="Primary"
                      />
                    </Tooltip>
                  </Grid>
                )}
              </Box>
            </FacilityAccordionSummary>
            <AccordionDetails>
              <Box>
                <Box display="flex" width="100%" px={1} alignItems="center">
                  <Box className={classes.facilityDetailsIcon}>
                    <PhoneOutlinedIcon />
                  </Box>
                  <Typography variant="body2">
                    {facility.phoneNumber}
                  </Typography>
                </Box>

                <Box display="flex" width="100%" px={1} alignItems="center">
                  <Box className={classes.facilityDetailsIcon}>
                    <LocationOnOutlinedIcon />
                  </Box>
                  <Typography variant="body2">
                    {facility.street}, {facility.city}, {facility.state}{' '}
                    {facility.zipCode}
                  </Typography>
                </Box>
                {facility.website && (
                  <Box display="flex" width="100%" px={1} alignItems="center">
                    <Box className={classes.facilityDetailsIcon}>
                      <LinkOutlinedIcon />
                    </Box>
                    <Typography variant="body2">{facility.website}</Typography>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  )
}

Facilities.propTypes = {
  formFields: formFields,
}

Facilities.defaultProps = {
  formFields: {
    facilities: [
      {
        facilityName: '',
      },
    ],
  },
}

export default Facilities
