import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@material-ui/core'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined'
import ActionsMenu from 'components/actions-menu'
import { useAuth } from 'lib/auth'
import { useStreamClient } from 'lib/stream-messaging/hooks'
import { useSnackbar } from 'notistack'
import { Fragment, useReducer, useState } from 'react'
import { useChatContext } from 'stream-chat-react'
import { LeaveGroupConversation } from '../icons/leave-group'
import EditGroupNameModal from './edit-group-name-modal'

const initialMenuState = {
  isConfirmationOpen: false,
  onSuccess: null,
  title: '',
  actionText: '',
  content: '',
}

function reducer(state, action) {
  switch (action.type) {
    case 'leave':
      return {
        isConfirmationOpen: true,
        onSuccess: action.onSuccess,
        successMessage: 'You have left the group',
        text: 'Leave Group',
        actionText: 'Leave Group',
        content:
          'Are you sure you want to leave? You will no longer be able to view or send messages to this group.',
      }
    case 'hide':
      return {
        isConfirmationOpen: true,
        onSuccess: action.onSuccess,
        successMessage: 'Conversation hidden',
        text: 'Hide Message',
        actionText: 'Hide',
        content:
          'Hiding this message will temporarily remove it from the list until you receive a new message or decide to unhide it.',
      }
    case 'close':
      return { ...initialMenuState }
    default:
      return state
  }
}

/**
 * Action menu for Stream conversation header.
 * Available actions: "Leave" and "Hide".
 * It automatically redirects to the next conversation on action completion.
 *
 * @param {Object} channel - the object of the current Stream channel
 */
function ConversationActionsMenu({ channel }) {
  const { user } = useAuth()
  const [state, dispatch] = useReducer(reducer, initialMenuState)
  const client = useStreamClient()
  const { setActiveChannel } = useChatContext()
  const { enqueueSnackbar } = useSnackbar()
  const [openEditNameModal, setOpenEditNameModal] = useState(false)
  const [channelName, setChannelName] = useState(channel?.data.name)
  const isChatOwner = channel?.data.created_by.id === user.externalId
  let isGroupConvo = false

  if (channel?.data.member_count > 2) isGroupConvo = true

  const filters = {
    members: { $in: [client?.user?.id] },
    frozen: false,
    hidden: false,
  }
  const sort = { last_message_at: -1 }

  const redirect = async () => {
    const [ch] = await client.queryChannels(filters, sort, { limit: 1 })
    if (ch != null) {
      setActiveChannel(ch)
    }
  }

  return (
    <Fragment>
      <ActionsMenu>
        <MenuItem
          onClick={() =>
            dispatch({ type: 'hide', onSuccess: () => channel.hide() })
          }
        >
          <VisibilityOffOutlined style={{ paddingRight: '6px' }} />
          Hide Conversation
        </MenuItem>
        {isGroupConvo ? (
          <>
            {isChatOwner && (
              <MenuItem onClick={() => setOpenEditNameModal(true)}>
                <EditOutlinedIcon style={{ paddingRight: '6px' }} />
                Edit Group Name
              </MenuItem>
            )}
            <MenuItem
              onClick={() =>
                dispatch({
                  type: 'leave',
                  onSuccess: () => channel.removeMembers([user.externalId]),
                })
              }
            >
              <LeaveGroupConversation style={{ paddingRight: '6px' }} />
              Leave Group
            </MenuItem>
          </>
        ) : null}
      </ActionsMenu>
      <ConfirmationDialog
        open={state.isConfirmationOpen}
        onSuccess={async () => {
          const action = state.successMessage
          await state.onSuccess()
          enqueueSnackbar(action, { variant: 'success' })
          await redirect()
        }}
        setOpen={() => dispatch({ type: 'close', value: null })}
        title={state.text}
        actionText={state.actionText}
        content={state.content}
      />
      <EditGroupNameModal
        open={openEditNameModal}
        setOpen={setOpenEditNameModal}
        channelName={channelName}
        setChannelName={setChannelName}
        channel={channel}
      />
    </Fragment>
  )
}

function ConfirmationDialog({
  open,
  onSuccess,
  setOpen,
  title,
  content,
  actionText,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen()} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen()}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={async () => await onSuccess()}
        >
          {actionText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConversationActionsMenu
