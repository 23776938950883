import React from 'react'
import AppCard from 'components/app-card'
import Typography from '@mui/material/Typography'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import { flags, useFeatureFlags } from 'lib/feature-flags'

export default function PGACoachBusinessInsights() {
  const [isCoachBusinessInsightsEnabled] = useFeatureFlags([
    flags.FLAG_FEAT_COACH_BUSINESS_INSIGHTS,
  ])

  return (
    <AppCard
      title={
        <Typography variant="inherit">
          Access the PGA Coach Business Insights <strong>prototype</strong>
        </Typography>
      }
      to={
        isCoachBusinessInsightsEnabled ? '/coach-business-insights' : undefined
      }
      url={
        !isCoachBusinessInsightsEnabled
          ? 'https://sites.google.com/pgahq.com/pgacoachbusinessinsights/home'
          : undefined
      }
      buttonText="launch"
      testid="pga-coach-business-insights-tile"
      logoStyle={{ height: '70px', width: '70px' }}
      logo={<BarChartOutlinedIcon style={{ color: '#00234B' }} />}
    />
  )
}
