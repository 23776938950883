import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_UPLOAD_PRESET_LARGE } from 'env'
import { formFields } from '../constants'
import uploadImage from 'lib/cloudinary/uploadImage'
import useStyles, {
  ErrorMsg,
  PhotoSelector,
  PhotoSelectorPhoto,
} from '../profile.styles'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useIsMobile } from 'lib/hooks'
import { useFieldArray, useFormContext } from 'react-hook-form'

const MAX_PHOTO_COUNT = 8

const uploadOptions = {
  cloudName: CLOUDINARY_CLOUDNAME,
  upload_preset: CLOUDINARY_UPLOAD_PRESET_LARGE,
  cropping: true,
  multiple: false,
  croppingAspectRatio: 1.7,
  showSkipCropButton: false,
  sources: ['local', 'camera'],
  resourceType: 'image',
  folder: 'dot-com-images',
  showPoweredBy: false,
}

const Photos = () => {
  const [uploadError, setUploadError] = useState(null)
  const [checkedPhotos, setCheckedPhotos] = useState([])
  const { control, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'photosJsonData',
    control,
  })
  const classes = useStyles()
  const isMobile = useIsMobile()

  const handleImageUpload = async () => {
    try {
      const ref = await uploadImage(uploadOptions)
      append({ rank: fields.length + 1, reference: ref })
      setUploadError(null)
    } catch (e) {
      setUploadError(e.statusText)
    }
  }

  const handleCheckboxToggle = (isChecking, rank) => {
    if (isChecking) {
      setCheckedPhotos(photos => [rank, ...photos])
    } else {
      const rankIndex = checkedPhotos.findIndex(photo => photo === rank)
      const checked = [...checkedPhotos]
      if (rankIndex !== -1) {
        checked.splice(rankIndex, 1)
        setCheckedPhotos(checked)
      }
    }
  }

  const handlePhotosDelete = deleteIndex => {
    if (deleteIndex >= 0) {
      remove(deleteIndex)
    } else {
      // else delete all checked photos
      checkedPhotos.forEach(rank => {
        remove(rank - 1)
      })
      setCheckedPhotos([])
    }
  }

  const transformHeicToJpg = (reference) => {
    return reference.replace('.heic', '.jpg')
  }

  return (
    <>
      {uploadError ? (
        <ErrorMsg>{uploadError}</ErrorMsg>
      ) : (
        <Typography variant="body2" color="textSecondary" gutterBottom>Showcase your best moments helping students grow! Great profiles have between 4-6 photos.</Typography>
      )}
      <Box className={classes.flexRow}>
        <Box className={classes.photosImagesWrapper}>
          {fields.map((photo, i) => (
            <PhotoSelector
              key={photo.rank}
              isSelected={checkedPhotos.find(pic => pic === photo.rank)}
              onClick={() =>
                handleCheckboxToggle(
                  !checkedPhotos.find(pic => pic === photo.rank),
                  photo.rank,
                )
              }
            >
              <PhotoSelectorPhoto src={transformHeicToJpg(photo.reference)} alt="Photos Preview" />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginLeft: '-2px',
                }}
              >
                <Checkbox
                  className="checkbox"
                  checked={!!checkedPhotos.find(pic => pic === photo.rank)}
                  color="secondary"
                />
                <IconButton
                  style={{ padding: 0, margin: '8px -6px 8px 8px' }}
                  onClick={() => handlePhotosDelete(i)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
              <input
                type="hidden"
                ref={register()}
                name={`photosJsonData[${i}].rank`}
                value={i + 1}
              />
              <input
                type="hidden"
                ref={register()}
                name={`photosJsonData[${i}].reference`}
                value={photo.reference}
              />
            </PhotoSelector>
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
      >
        <Button
          variant="outlined"
          color="primary"
          type="button"
          disabled={fields.length >= MAX_PHOTO_COUNT}
          onClick={() => handleImageUpload()}
        >
          Upload Photo
        </Button>
      </Box>
    </>
  )
}

Photos.defaultProps = {
  values: formFields,
  setFieldValue: PropTypes.func,
}

export default Photos
