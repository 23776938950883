export const formatPhone = phoneNumberString => {
  if (phoneNumberString) {
    const parts = phoneNumberString.split('+1')
    if (parts) {
      return parts[1]
    }
  }
  return null
}

export const makeProfileObj = data => ({
  ...data,
  phoneNumber: formatPhone(data.phoneNumber),
  mobileNumber: formatPhone(data.mobileNumber),
  facilities:
    data.coach.facilities.length &&
    data.coach.facilities
      .filter(facility => {
        return facility.latitude && facility.longitude
      })
      .sort((a, b) => {
        const aRank = a.coachProfilesFacilities[0]?.facilityRank
        const bRank = b.coachProfilesFacilities[0]?.facilityRank

        /* eslint eqeqeq: "off" */
        if (aRank == bRank) return 0
        if (aRank == null) return 1
        if (bRank == null) return -1
        if (aRank < bRank) return -1
        if (aRank > bRank) return 1
        return 1
      })
      .map((facility, index) => ({
        facilityId: facility.id,
        facilityName: facility.name,
        street: facility.address1,
        zipCode: facility.zip,
        pgaFacilityId: facility.externalFacilityId,
        city: facility.city,
        state: facility.state,
        website: facility.url,
        isPrivate: facility.isPrivate,
        customData: {
          ...facility.coachProfilesFacilities[0],
          facilityRank: index + 1,
        },
        phoneNumber: facility.phone,
      })),
})
