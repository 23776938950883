import { PGA_COM_HOST } from 'env'

import { DateTime } from 'luxon'
import {
  toFullDateAndTime,
  fullDayDateAndTimeWithTimezone,
} from 'utils/dateUtils'
import { centsToDollarsFormatted } from 'lib/utils/number'

export const parseEvents = (events, selectedTab = null) => {
  const parsedEvents = events.map(parseEvent)
  if (selectedTab === null) {
    return parsedEvents
  }
  if (selectedTab === 'past') {
    return parsedEvents.filter(e => e.futureSessions.length === 0)
  }
  if (selectedTab === 'upcoming') {
    return parsedEvents.filter(
      e => e.futureSessions.length === e.sessions.length,
    )
  }
  if (selectedTab === 'inProgress') {
    return parsedEvents.filter(e => e.futureSessions.length < e.sessions.length)
  }
}

export const parseEvent = e => ({
  ...e,
  startDateTime: DateTime.fromISO(e.sessions[0].startDateTime),
  endDateTime: DateTime.fromISO(e.sessions[0].endDateTime),
  timezone: e.timezone,
  isPast: () =>
    DateTime.fromISO(e.seriesEndDateTime) < DateTime.local().startOf('day'),
  featuredImageUrl: e.featuredImageUrl,
  totalEnrolled: e.registrationCount,
  facilityName: e.coachFacility.name,
  eventDayAndTime: fullDayDateAndTimeWithTimezone(
    e.sessions[0].startDateTime,
    e.sessions[0].endDateTime,
    e.timezone,
  ),
  closestEventDayAndTime: fullDayDateAndTimeWithTimezone(
    e.sessions.filter(
      s => DateTime.fromISO(s.startDateTime) > DateTime.local().startOf('day'),
    )[0]?.startDateTime,
    e.sessions.filter(
      s => DateTime.fromISO(s.startDateTime) > DateTime.local().startOf('day'),
    )[0]?.endDateTime,
    e.timezone,
  ),
  price: centsToDollarsFormatted(e.priceInCents),
  totalPrice: e.totalPriceInCents
    ? centsToDollarsFormatted(e.totalPriceInCents)
    : null,
  registrationCloseText: toFullDateAndTime(
    e.registrationCloseDateTime,
    e.timezone,
  ),
  eventUrl: `${PGA_COM_HOST}/things-to-do/events/${e.slug}`,
  tags: e.tags.length ? e.tags : [],
  sessions: e.sessions,
  futureSessions: e.sessions.filter(
    s => DateTime.fromISO(s.startDateTime) > DateTime.local().startOf('day'),
  ),
  unlisted: e.unlisted,
})
