import React, { useState, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { PGA_COM_HOST } from 'env'
import useStyles, {
  CardRoot,
  HelperMsg,
  WebAddressLink,
} from '../profile.styles'
import ExternalLinkButton from 'components/external-link-button'
import { useIsMobile } from 'lib/hooks'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { useFormContext } from 'react-hook-form'

const WebAddress = ({ formFields }) => {
  const classes = useStyles()
  const { register } = useFormContext()
  const customSlugValue = formFields.customSlug
  const [copySuccessMessage, setCopySuccessMessage] = useState('')
  const clipboardInputRef = useRef(null)
  const isMobile = useIsMobile()

  const copyToClipboard = e => {
    // select and copy text
    clipboardInputRef.current.type = 'text'
    clipboardInputRef.current.select()
    document.execCommand('copy')
    clipboardInputRef.current.type = 'hidden'

    // Don't show the the whole text area selected.
    e.target.focus()
    setCopySuccessMessage('Copied!')
  }

  return (
    <CardRoot style={{ alignItems: 'flex-start' }}>
      <Typography variant="subtitle1">
        Your Profile Name
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ textAlign: isMobile ? 'left' : 'center' }}
      >
        Your coach profile is live on PGA.com. Click below to copy your unique link or to view your profile.
      </Typography>
        <>
          <form>
            <input
              ref={clipboardInputRef}
              type="hidden"
              value={`${PGA_COM_HOST}/coach/${customSlugValue}`}
            />
          </form>
          <Box my={3} style={{ width: '100%' }}>
            <WebAddressLink onClick={copyToClipboard} variant="h6">
              <span>{`${PGA_COM_HOST}/coach/${customSlugValue}`}</span>
              <FileCopyOutlinedIcon
                aria-label="copy"
                fontSize="small"
                className={classes.icon}
              />
            </WebAddressLink>
            {copySuccessMessage && (
              <HelperMsg
                style={{
                  textAlign: 'center',
                  lineHeight: 0,
                  margin: '0.5rem 0 0 0',
                }}
              >
                {copySuccessMessage}
              </HelperMsg>
            )}
          </Box>
        </>
      <ExternalLinkButton
        variant="outlined"
        color="primary"
        href={`${PGA_COM_HOST}/coach/${customSlugValue}`}
      >
        View Profile
      </ExternalLinkButton>
      <input type="hidden" ref={register()} name="customSlug" />
    </CardRoot>
  )
}

export default WebAddress
