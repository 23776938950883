import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { TestimonyFields } from '../profile.styles'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { CtrlTextField } from 'components/form-util'

const MAX_TESTIMONIALS_COUNT = 5

const Testimonials = () => {
  const { control, errors, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'testimonialsJsonData',
    control,
  })

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        Have any kudos from past students you want to share? Add them here!
      </Typography>
      {fields.map((item, i) => (
        <Box key={i}>
          <Box mt={2}>
            <TestimonyFields>
              <CtrlTextField
                multiline
                name={`testimonialsJsonData[${i}].testimonial`}
                label="Testimonial"
                variant="outlined"
                className="text-input multiline"
                control={control}
                errors={errors}
              />
            </TestimonyFields>
            <Box style={{ maxWidth: '500px' }} display="flex">
              <CtrlTextField
                name={`testimonialsJsonData[${i}].author`}
                label="Attestant"
                variant="standard"
                className="text-input"
                control={control}
                errors={errors}
              />
              <input
                type="hidden"
                ref={register()}
                name={`testimonialsJsonData[${i}].rank`}
                value={i + 1}
              />
              <Box p={2}>
                <Button
                  variant="text"
                  onClick={() => remove(i)}
                  startIcon={<DeleteOutlinedIcon />}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
      <Box>
        <Button
          style={{ marginTop: '20px' }}
          variant="outlined"
          color="primary"
          type="button"
          onClick={() => {
            append({ testimonial: '', author: '' })
          }}
          disabled={fields.length >= MAX_TESTIMONIALS_COUNT}
        >
          Add Testimonial
        </Button>
      </Box>
    </>
  )
}

export default Testimonials
