import React from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { useFormContext } from 'react-hook-form'

const VideoIntroduction = () => {
  const { errors, register } = useFormContext()
  return (
    <>
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: '1rem' }}>
        Share your story with potential students on your PGA Coach Profile on PGA.com! Record a brief, informal video introducing yourself, describing your coaching philosophy, experience, and what makes you unique as a PGA Coach. Once your video is ready, simply add the YouTube link below to highlight your expertise and attract new leads. <strong>We do not support YouTube Shorts at this time.</strong>
      </Typography>
      <TextField
        key="introductionVideoUrl"
        name="introductionVideoUrl"
        label="YouTube"
        type="text"
        inputRef={register()}
        variant="standard"
        placeholder="https://www.youtube.com/watch?v=AMozaT8GCFg&ab_channel=PGAofAmerica"
        className={`text-input ${errors.introductionVideoUrl && ' error'}`}
        error={!!errors.introductionVideoUrl}
        helperText={errors.introductionVideoUrl?.message}
      />
    </>
  )
}

export default VideoIntroduction
