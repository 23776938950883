import React from 'react'
import { gql, useQuery } from '@apollo/client'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/error'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
  CardRoot,
  InputsTwoColumns,
  CheckboxItemWrapper,
  ErrorMsg,
} from '../profile.styles'
import { findIndex, some } from 'lodash'
import { useFieldArray, useFormContext } from 'react-hook-form'

const MAX_SKILLS = 8

const SkillsQuery = gql`
  query Skills {
    Skills {
      message
      success
      skills {
        skillId
        skillName
        description
      }
    }
  }
`

const isChecked = (skillId, skills) => some(skills, { skillId })

const toggleSkillChecked = (skillId, skills, push, remove) => {
  isChecked(skillId, skills)
    ? remove(findIndex(skills, { skillId }))
    : push({ skillId })
}

const Skills = () => {
  const { register, errors, control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: 'skills',
    control,
  })

  const { data, loading, error } = useQuery(SkillsQuery)
  if (error) {
    return <Error error={error} displayErrorType="page" />
  }
  if (loading) return <LinearProgress color="secondary" />
  const response = data && data.Skills && data.Skills.skills

  return response ? (
    <div key="profile-form-skills">
      {errors.skills && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>{errors.skills.message}</ErrorMsg>
      )}
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <input
              type="hidden"
              ref={register()}
              name={`skills[${index}].skillId`}
              value={item.skillId}
            />
          </div>
        )
      })}
      <CardRoot>
        <Typography variant="body2" color="textSecondary">
          Choose any of these pre-populated options to help market your business and specialties!
        </Typography>
        <InputsTwoColumns>
          {data.Skills.skills.map(item => {
            return (
              <CheckboxItemWrapper key={item.skillId}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`skill-checkbox-${item.skillId}`}
                      className="checkbox"
                      checked={isChecked(item.skillId, fields)}
                      onChange={() =>
                        toggleSkillChecked(
                          item.skillId,
                          fields,
                          append,
                          remove,
                        )
                      }
                      value={item.skillId}
                      color="secondary"
                      disabled={
                        !isChecked(item.skillId, fields) &&
                        fields.length >= MAX_SKILLS
                      }
                    />
                  }
                  label={item.skillName}
                  htmlFor={`skill-checkbox-${item.skillId}`}
                />
              </CheckboxItemWrapper>
            )
          })}
        </InputsTwoColumns>
      </CardRoot>
    </div>
  ) : null
}

export default Skills
