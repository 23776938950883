import { makeStyles, styled } from '@material-ui/core/styles'
import BackgroundImage from 'images/golf-field-bg-bw.jpeg'
import HopeBackgroundImage from 'images/hope-bg-bw.png'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

const RootPage = styled('div')(({ theme, isPgaHopeEvent }) => ({
  width: '100%',
  height: '100vh',
  background: isPgaHopeEvent
    ? `linear-gradient(rgba(0, 32, 91, 0.70), rgba(0, 32, 91, 0.70)), url(${HopeBackgroundImage})`
    : `linear-gradient(rgba(0, 32, 91, 0.70), rgba(0, 32, 91, 0.70)), url(${BackgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  [theme.breakpoints.only('xs')]: {
    backgroundImage: 'none',
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
}))

const StyledDescription = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: '600',
  fontFamily: 'Nunito Sans',
  lineHeight: '1.8',
}))

const StyledEventTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontFamily: 'Nunito Sans',
}))

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  minHeight: '133px',
  minWidth: '370px',
  borderRadius: '12px',
  textAlign: 'left',
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    minHeight: '150px',
  },
}))

const StyledCardMedia = styled('div')(({ theme, background }) => ({
  display: 'flex',
  height: 'auto',
  minWidth: '80px',
  minHeight: '100%',
  background: background,
}))

const StyledLogo = styled('img')(({ theme }) => ({
  width: '86px',
  height: '86px',
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  margin: '48px auto 8px auto',
  color: theme.palette.common.white,
  backgroundColor: '#0053FF',
  '&:hover': {
    backgroundColor: '#0053FFE5',
  },
  [theme.breakpoints.only('xs')]: {
    margin: '48px auto 24px auto',
  },
}))

const ParticipantsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '300px',
  marginTop: theme.spacing(2),
  [theme.breakpoints.only('xs')]: {
    height: 'calc(100vh - 339px)',
  },
}))

const ParticipantsListWrapper = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  [theme.breakpoints.only('xs')]: {
    height: 'calc(100vh - 489px)',
  },
}))

const UpdateRsvpButton = styled(Button)(({ theme }) => ({
  color: '#0053FF',
  marginLeft: '0px',
  paddingLeft: '0px',
  fontSize: '14px',
  fontWeight: '600',
}))

const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '54px',
  boxShadow: '0px 4px 36px 0px rgba(0, 10, 22, 0.13)',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const StyledCheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}))

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '500px',
    height: '660px',
    textAlign: 'center',
    padding: '40px 32px',
    [theme.breakpoints.only('xs')]: {
      width: 'unset',
      height: 'calc(100vh - 78px)',
      padding: '0 24px 24px 24px',
    },
  },
  dialogPaper: {
    borderRadius: '12px',
    [theme.breakpoints.only('xs')]: {
      borderRadius: 'unset',
    },
  },
  hopeRoot: {
    paddingTop: '24px',
    [theme.breakpoints.only('xs')]: {
      paddingTop: 'unset',
    },
  },
}))

export default useStyles
export {
  StyledTitle,
  StyledDescription,
  StyledEventTitle,
  StyledCard,
  StyledLogo,
  StyledButton,
  UpdateRsvpButton,
  StyledCheckIcon,
  StyledCancelIcon,
  RootPage,
  StyledCardMedia,
  LogoWrapper,
  ParticipantsWrapper,
  ParticipantsListWrapper,
}
