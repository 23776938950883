import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import { get } from 'lodash'
import LinearProgress from '@material-ui/core/LinearProgress'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { Box, Grid, TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'

const PricesQuery = gql`
  query Prices {
    Prices {
      message
      success
      prices {
        priceId
        price
      }
    }
  }
`

const PricesComp = ({ prices }) => {
  const { control, errors, setValue } = useFormContext()
  const isError = !!errors.prices

  const handlePriceChange = e => {
    setValue('prices', [{ priceId: e.target.value }], {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">Price</Typography>
        <Typography variant="body2" color="textSecondary">
          How much do you charge per lesson?
        </Typography>
        <InputLabel error={isError} style={{ marginTop: '10px' }}>
          Price
        </InputLabel>
        <FormControl error={!!errors.prices} style={{ maxWidth: '450px' }}>
          <Controller
            render={({ onChange, value }) => (
              <TextField
                select
                name="price"
                data-testid="price-input"
                variant="standard"
                error={!!errors.prices}
                helperText={errors?.prices?.message}
                onChange={e => {
                  onChange(e)
                  handlePriceChange(e)
                }}
                value={(value && value[0]?.priceId) || ''}
              >
                {prices.map(opt => (
                  <MenuItem key={opt.priceId} value={opt.priceId}>
                    {opt.price}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="prices"
            control={control}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

PricesComp.propTypes = {
  prices: PropTypes.array,
}

export default function Price() {
  const { data, loading, error } = useQuery(PricesQuery)
  if (loading) return <LinearProgress color="secondary" />
  if (error) return <Box>ERROR</Box>

  return <PricesComp prices={get(data, ['Prices', 'prices'], [])} />
}
