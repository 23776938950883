import React from 'react'
import LegalDoc from './legal-doc'

export default function JRLPlayerWaiver() {
  const currentYear = new Date().getFullYear()
  const regOpenDate = currentYear === 2024 ? 9 : 7
  return (
    <LegalDoc>
      <p>
        In consideration of the right of Participant to participate in the{' '}
        {currentYear} PGA JR. LEAGUE PROGRAM, including in post-season and/or
        championship play, to be held from January {regOpenDate} to December 31,{' '}
        {currentYear} (the “Program”), I hereby, on behalf of myself and the
        Participant (for whom I am the authorized parent/legal guardian), agree
        to the following:
      </p>

      <p>
        Medical Release. I, on my own behalf and on behalf of Participant,
        acknowledge and agree that participation in the Program subjects
        Participant to the possibility of physical illness or injury (minimal,
        serious, catastrophic and/or death) including, without limitation, the
        risk of contracting a communicable disease or illness, and that I, on my
        own behalf and on behalf of Participant, acknowledge that Participant is
        assuming the risk of such illness or injury by participating in the
        Program. In the event of such illness or injury, I, on my own behalf and
        on behalf of Participant, authorize The Professional Golfers’
        Association of America (the “PGA of America”) (or its authorized
        agents/affiliates) to obtain necessary medical treatment for Participant
        and hereby, on my own behalf and on behalf of Participant, release and
        hold harmless the PGA of America and all affiliated entities (including,
        but not limited to, Program host golf courses and Program vendors), all
        Program sponsors and each of their subsidiaries, trustees, officers,
        directors, staff, employees, volunteers and authorized representatives
        (“Releasees”) in the exercise of this authority. I further acknowledge
        and understand that I will be responsible for any and all medical and
        related bills that may be incurred on behalf of Participant for any
        illness or injury that Participant may sustain during the Program and/or
        while traveling to and from the site(s) for the Program whether or not
        the Program actually occurs.
      </p>

      <p>
        Insurance and Medical Information. I represent that any medication to
        which Participant is allergic or medications that Participant is
        currently taking will be identified by me to the Participant’s Program
        Coach. I agree that Participant shall bring medications which
        Participant is required to take with him/her to the Program and that
        he/she shall consume the prescribed dosage for such medications. The PGA
        of America will not, and will not allow any Program associated entity or
        individual to, administer any medications while the Participant is on
        site.
      </p>

      <p>
        I, on my own behalf and on behalf of Participant, am fully aware of the
        risks and hazards connected with Participant’s participation in this
        Program, and I hereby elect to voluntarily allow Participant to
        participate in said Program. I, on my own behalf and on behalf of
        Participant, VOLUNTARILY ASSUME FULL RESPONSIBILITY FOR ANY RISKS OF
        LOSS, PROPERTY DAMAGE OR PERSONAL INJURY, INCLUDING DEATH, that may be
        sustained by Participant, or any loss or damage to Participant’s
        property, as a result of participating in this Program, or while in, on,
        or upon the premises where the Program is being conducted or while in
        transit during and to and from said Program.
      </p>

      <p>
        In consideration of the right to participate in the Program, I, on my
        own behalf and on behalf of Participant, hereby COVENANT NOT TO SUE, and
        further HOLD HARMLESS, RELEASE, WAIVE, and DISCHARGE the Releasees from
        any and all liability, claims, demands, losses, actions and causes of
        action whatsoever (including, but not limited to, the negligence of the
        Releasees) arising out of or related to (in whole or in part) any loss,
        damage, or injury (including, but not limited to, physical or mental
        injury, aggravation of any pre-existing illness or condition, permanent
        disability or death), that may be sustained by me and/or Participant, or
        to any property belonging to me and/or Participant, or otherwise,
        arising out of Participant’s or my participation in the Program and/or
        traveling to or from any Program-related activity and/or event. I
        further AGREE TO INDEMNIFY AND HOLD HARMLESS the Releasees from any
        loss, liability, damages, or costs, including, but not limited to, court
        costs and attorney's fees, that may result from Participant and/or my
        being allowed to participate in the Program.
      </p>

      <p>Further, I hereby:</p>

      <ol>
        <li>
          agree that prior to allowing Participant to participate, I will
          inspect the facilities and equipment to be used, and if I believe
          anything is unsafe, I will immediately advise an official of such
          conditions(s) and refuse to participate until such conditions(s) is
          corrected;
        </li>

        <li>
          agree that my/Participant’s acts, poses, words and performances at the
          Program will be in good taste and will not reflect negatively on the
          image, reputation or business of the Releasees and agree to indemnify
          and save and hold harmless each of the Releasees from any loss,
          liability, damage or cost they may incur due to Participant’s
          participation in or otherwise in connection with the Program, whether
          caused by the negligence of any Releasees or otherwise;
        </li>

        <li>
          grant full permission to each of the Releasees, with no obligation to
          compensate me or Participant or to provide me or Participant any right
          to review or pre-approve, to use each of Participant’s and my name,
          voice, statements, image, likeness, and/or actions, in addition to any
          photographs, videotapes, motion pictures, recordings, and/or other
          record of Participant’s my performances(s) in the Program in
          perpetuity worldwide in any manner (as determined by the Releasees)
          and in any and all media, whether now known or unknown or hereafter
          devised, and represent that I have the right to grant such permission;
        </li>

        <li>
          understand and agree that (a) my refusal to accept any prize from any
          game, event or contest (each, a “Contest”) or other activity shall
          constitute a forfeiture of that prize and shall release the Releasees
          from any and all obligations in connection with such prize; (b) I am
          responsible for and shall pay all state and federal or other taxes on
          any and all cash and/or prizes I win; (c) I will indemnify and hold
          the Releasees harmless against any liability for any such taxes and
          agree that any of the Releasees may deduct or require payment of any
          such tax before delivery of a prize; and (d) any travel undertaken by
          me in the connection with my acceptance or use of any prize shall be
          taken at my sole risk. FURTHERMORE, I FULLY ACKNOWLEDGE, APPRECIATE
          AND UNDERSTAND THE RISK THAT MY PARTICIPATION IN ANY CONTEST,
          ACCEPTANCE OF ANY PRIZES AND/OR PGA JR. LEAGUE’S USE OF THE MATERIALS
          COULD AFFECT ANY HIGH SCHOOL OR INTERCOLLEGIATE ATHLETIC ELIGIBILITY
          THAT I MAY HAVE, AND I FULLY ASSUME SUCH RISK AND RELEASE THE RELEASED
          PARTIES FROM ANY LIABILITY IN CONNECTION WITH ANY SUCH FAILURE TO BE
          ELIGIBLE FOR HIGH SCHOOL OR INTERCOLLEGIATE ATHLETIC COMPETITION;
        </li>

        <li>
          expressly agree that (a) this RELEASE AND WAIVER OF LIABILITY AND
          INDEMNITY AGREEMENT is intended to be as broad and inclusive as is
          permitted by Florida law (which shall govern this RELEASE AND WAIVER
          OF LIABILITY AND INDEMNITY AGREEMENT) and that if any portion hereof
          is held invalid, it is agreed that the balance shall nonetheless
          continue in full legal force and effect and (b) for the purposes of
          any action or proceeding arising out of this RELEASE AND WAIVER OR
          LIABILITY AND INDEMNITY AGREEMENT, I expressly submit to the
          jurisdiction of all federal and state courts located in the State of
          Florida; and
        </li>

        <li>
          acknowledge that the Releasees are relying on the grant of rights
          contained herein.
        </li>
      </ol>

      <p>
        I hereby certify that I and/or my child have and will abide by the rules
        and regulations of the Program, as such are established by the PGA of
        America, and that the information stated on the form is true and
        correct. I acknowledge that any violation of the previous statement
        shall result in immediate disqualification of me or Participant from any
        and/or all PGA JR. LEAGUE events.
      </p>

      <p>
        It is my express intent that this Release and Waiver shall bind the
        members of the Participant’s family, heirs, assigns and personal
        representative(s). I further agree that this Release and Waiver shall be
        construed in accordance with the laws of the State of Florida.
      </p>

      <p>
        I hereby warrant that I have read this Release and Waiver Form in its
        entirety and fully understand its contents. I, on my own behalf and on
        behalf of Participant, am aware that this Release and Waiver Form
        releases Releasees’ liability and contains an acknowledgement of my (on
        my own behalf and on behalf of Participant) voluntary and knowing
        assumption of the risk of injury or illness. I, on my own behalf and on
        behalf of Participant, further acknowledge that nothing in this Release
        and Waiver Form constitutes a guarantee that the Program will occur.
      </p>

      <p>
        If this Release and Waiver Form is being executed by an authorized
        Parent and/or Legal Guardian for a minor, such Parent and/or Legal
        Guardian hereby accepts and agrees to all of the terms and conditions of
        this Release and Waiver Form on behalf of the minor in connection with
        the minor’s participation in the Program. If, despite this Release and
        Waiver Form, such authorized Parent and/or Legal Guardian, or anyone on
        the minor’s behalf, makes a claim for liability against any of the
        Releasees, such authorized Parent and/or Legal Guardian will indemnify,
        defend, and hold harmless each of the Releasees from any liabilities
        which may be incurred as the result of such claim.
      </p>

      <p>
        By selecting the "I Accept" button, you are signing this Release and
        Waiver Form electronically. You agree your electronic signature is the
        legal equivalent of your manual signature on this Release and Waiver
        Form. By selecting "I Accept" you consent to be legally bound by this
        Release and Waiver Form's terms and conditions and acknowledge that you
        have signed this document voluntarily and of your own free will.
      </p>
    </LegalDoc>
  )
}
