import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import { formFields } from '../constants'
import { useIsMobile } from 'lib/hooks'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import { useFormContext } from 'react-hook-form'
import { get } from 'lodash'

const MAX_TOOLS = 8
const toolsMsg = `Add up to ${MAX_TOOLS} tools that apply to your coaching practice.`

const Tools = ({ formFields }) => {
  const { setValue, errors, register } = useFormContext()
  const isMobile = useIsMobile()
  const tools = formFields.toolJsonData || []
  const [displayTools, setDisplayTools] = useState(tools)

  const handleAddTool = () => {
    setDisplayTools((displayTools) => [...displayTools, ''])
  }

  const handleDeleteTool = (index) => {
    const newTools = [...tools]
    newTools.splice(index, 1)
    setValue('toolJsonData', newTools)
    setDisplayTools(newTools)
  }

  return (
    <div key="profile-form-tools">
      <Typography variant="body2" color="textSecondary">
        {toolsMsg}
      </Typography>
      <Box style={{ paddingTop: '1rem' }}>
        {displayTools.map((item, i) => {
          return (
            <Box
              key={i}
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems="flex-start"
              justifyContent="flex-start"
              pb={3}
            >
              <TextField
                name={`toolJsonData[${i}]`}
                placeholder="Tool"
                type="text"
                variant="standard"
                inputRef={register()}
                className={`multiline ${
                  !!get(errors, ['toolJsonData', i]) && ' error'
                }`}
                error={!!get(errors, ['toolJsonData', i])}
                helperText={get(errors, ['toolJsonData', i, 'message'])}
                InputProps={{
                  endAdornment: !isMobile && (
                    <InputAdornment position="end">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => handleDeleteTool(i)}
                        startIcon={<DeleteOutlinedIcon />}
                      >
                        Delete
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              {isMobile && (
                <Box pt={2}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<DeleteOutlinedIcon />}
                    onClick={() => handleDeleteTool(i)}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          )
        })}
      </Box>
      <Button
        variant="outlined"
        color="primary"
        type="button"
        onClick={() => handleAddTool()}
        disabled={displayTools.length >= MAX_TOOLS}
      >
        Add Tool
      </Button>
    </div>
  )
}

Tools.propTypes = {
  formFields: formFields,
}

export default Tools
