import React from 'react'
import { gql, useQuery } from '@apollo/client'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Error from 'components/error'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {
  InputsTwoColumns,
  CheckboxItemWrapper,
  ErrorMsg,
} from '../profile.styles'
import { findIndex, some } from 'lodash'
import { useFieldArray, useFormContext } from 'react-hook-form'

const ServicesQuery = gql`
  query Services {
    Services {
      message
      success
      serviceTypes {
        serviceTypeId
        typeName
        description
        services {
          serviceId
          serviceName
          serviceType
        }
      }
    }
  }
`

const isChecked = (serviceId, services) => some(services, { serviceId })

const toggleServiceChecked = (serviceId, services, push, remove) => {
  isChecked(serviceId, services)
    ? remove(findIndex(services, { serviceId }))
    : push({ serviceId })
}

const Services = () => {
  const { register, errors, control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    name: 'services',
    control,
  })

  const { data, loading, error } = useQuery(ServicesQuery)
  if (error) {
    return <Error error={error} displayErrorType="page" />
  }
  if (loading) return <LinearProgress color="secondary" />
  const response = data && data.Services && data.Services.serviceTypes

  return response ? (
    <div>
      <Typography variant="body2" color="textSecondary">
        Choose any of these pre-populated options to help market your business and specialities!
      </Typography>
      {errors.services && (
        <ErrorMsg style={{ marginTop: '-0.5rem' }}>{errors.services.message}</ErrorMsg>
      )}
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <input
              type="hidden"
              ref={register()}
              name={`services[${index}].serviceId`}
              value={item.serviceId}
            />
          </div>
        )
      })}
      {response.map(serviceType => {
        return (
          <Box key={serviceType.typeName} pt={3}>
            <Typography variant="subtitle1">{serviceType.typeName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {serviceType.description}
            </Typography>
            <InputsTwoColumns>
              {serviceType.services.map((service, index) => {
                return (
                  <CheckboxItemWrapper key={service.serviceId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() =>
                            toggleServiceChecked(service.serviceId, fields, append, remove)
                          }
                          checked={isChecked(service.serviceId, fields)}
                          color="secondary"
                          className="checkbox"
                        />
                      }
                      value={service.serviceId}
                      name={`services[${index}].serviceId`}
                      label={service.serviceName}
                      color="secondary"
                    />
                  </CheckboxItemWrapper>
                )
              })}
            </InputsTwoColumns>
          </Box>
        )
      })}
    </div>
  ) : (
    <Error />
  )
}

export default Services
