import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { TextField } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'

const EditGroupNameModal = ({
  open,
  setOpen,
  channelName = '',
  setChannelName,
  channel,
}) => {
  const handleUpdateName = () => {
    channel.update({ name: channelName.trim() })
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(!open)} maxWidth="md">
      <DialogTitle>Update Name</DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          variant="outlined"
          label="Group Name"
          defaultValue={channelName}
          inputProps={{ maxLength: 30 }}
          onChange={e => setChannelName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleUpdateName}>
          Update Name
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditGroupNameModal
