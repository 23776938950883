import React from 'react'
import Dialog from 'components/dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { getInitials } from 'lib/text-display'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  avatarStyles: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
  },
  dialogContent: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  itemText: {
    flex: '0 0 auto',
    [theme.breakpoints.only('xs')]: {
      flex: '1 1 auto',
    },
    marginRight: theme.spacing(1),
  },
}))

const MembersModal = ({ channel, trigger, currentUserId }) => {
  const styles = useStyles()
  const membersArray = Object.values(channel.state?.members || {})
  return (
    <Dialog trigger={trigger} fullWidth maxWidth="sm">
      {({ closeDialog }) => (
        <>
          <DialogTitle disableTypography style={{ paddingLeft: '32px' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Members</Typography>
              <IconButton onClick={closeDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers className={styles.dialogContent}>
            {membersArray.map(member => (
              <ListItem key={member.user.id}>
                <Avatar
                  aria-label="member"
                  src={member.user?.image}
                  className={styles.avatarStyles}
                >
                  {!member.user?.image && getInitials(member.user.name)}
                </Avatar>
                <ListItemText
                  primary={member.user.name}
                  classes={{ root: styles.itemText }}
                />
                {member.user.id === currentUserId && (
                  <ListItemText secondary="(You)" />
                )}
              </ListItem>
            ))}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default MembersModal
