import { makeStyles } from '@material-ui/styles'
const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  filler: {
    flexGrow: 1,
  },
  brandSwitcherContainer: {
    display: 'flex',
    width: `${drawerWidth - 35}px`, // this -35 was originally -24 -11
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  brandSwitcherButton: {
    minWidth: 'fit-content',
    padding: 0,
    width: '40px',
    height: '40px',
  },
  appBar: ({ assumedUser }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
    marginTop: assumedUser ? '30px' : 'unset',
    [theme.breakpoints.only('xs')]: {
      marginTop: assumedUser ? '70px' : 'unset',
    },
  }),
  toolbar: {
    justifyContent: 'space-between',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerContainerClosed: {
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerPaper: ({ assumedUser }) => ({
    width: drawerWidth,
    marginTop: assumedUser ? '30px' : 'unset',
    [theme.breakpoints.only('xs')]: {
      marginTop: assumedUser ? '70px' : 'unset',
    },
  }),
  drawerOpen: ({ assumedUser }) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: assumedUser ? '30px' : 'unset',
    [theme.breakpoints.only('xs')]: {
      marginTop: assumedUser ? '70px' : 'unset',
    },
  }),
  drawerClose: ({ assumedUser }) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    marginTop: assumedUser ? '30px' : 'unset',
    [theme.breakpoints.only('xs')]: {
      marginTop: assumedUser ? '70px' : 'unset',
    },
    '& span': {
      opacity: '0',
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        delay: theme.transitions.duration.leavingScreen,
        duration: '0s',
      }),
    },
  }),
  menu: {
    padding: 0,
  },
  mainContent: ({ assumedUser }) => ({
    flexGrow: 1,
    marginTop: assumedUser ? '30px' : 'unset',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: assumedUser ? '70px' : 'unset',
    },
  }),
  toolbarSpacing: {
    ...theme.mixins.toolbar,
    minHeight: '61px',
  },
  mobileMenuButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileMenuButtonBadge: {
    right: '-2px',
  },
  tabletMenuButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    marginLeft: -theme.spacing(2.5),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))
export default useStyles
