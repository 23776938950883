import React, { Fragment, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { Helmet } from 'react-helmet'
import Container from '@material-ui/core/Container'
import DialogAPIMessage from './profile-components/dialogAPIMessage'
import { initForm, editValidationSchema } from './constants'
import Navigation from './profile-components/navigation'
import useStyles from './profile.styles'
import { submissionHandler } from './utils'
import ErrorDisplay from './ErrorDisplay'
import WebAddress from './profile-components/webaddress'
import Profile from './profile-components/profile'
import Testimonials from './profile-components/testimonials'
import Photos from './profile-components/photos'
import Facilities from './profile-components/facilities'
import Experience from './profile-components/experience'
import Services from './profile-components/services'
import Skills from './profile-components/skills'
import Tools from './profile-components/tools'
import { PageHeader } from 'components/page-header'
import { CardHeader, CardContent, Card } from '@material-ui/core'
import { FormActionBar } from 'components/form-util'
import Button from '@material-ui/core/Button'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import { useIsMobile } from 'lib/hooks'
import SaveDialog from './profile-components/dialog'
import VideoIntroduction from './profile-components/video-introduction'
import { yupResolver } from '@hookform/resolvers'

const UPDATE_USER = gql`
  mutation CoachProfileUpdate(
    $coachProfileId: ID!
    $params: CoachProfileUpdateInput!
  ) {
    CoachProfileUpdate(coachProfileId: $coachProfileId, params: $params) {
      message
      success
    }
  }
`

const ProfileForm = ({ formFields }) => {
  // dialog state
  const [openApiResponseDialog, setOpenApiResponseDialog] = useState(false)
  const [apiResponseObject, setApiResponseObject] = useState()
  const handleDialogClose = () => setOpenApiResponseDialog(false)
  const classes = useStyles()
  const isMobile = useIsMobile()

  const [updateProfile, { loading }] = useMutation(UPDATE_USER)

  const form = useForm({
    defaultValues: initForm(formFields),
    resolver: yupResolver(editValidationSchema),
  })

  const { handleSubmit, errors, formState } = form
  const {
    isSubmitting,
    touched,
  } = formState

  const onSubmit = (values) => {
    submissionHandler(
      values,
      false,
      updateProfile,
      formFields && formFields.coachProfileId,
      setApiResponseObject,
      setOpenApiResponseDialog,
      formFields,
    )
  }

  const profileSectionsConfig = [
    {
      id: 'web-address',
      label: 'Profile Name',
      Component: WebAddress,
    },
    {
      id: 'profile',
      label: 'Profile',
      Component: Profile,
    },
    {
      id: 'video-introduction',
      label: 'Video Introduction',
      Component: VideoIntroduction,
    },
    {
      id: 'testimonials',
      label: 'Testimonials',
      Component: Testimonials,
    },
    {
      id: 'photos',
      label: 'Photos',
      Component: Photos,
    },
    {
      id: 'facilities',
      label: 'Facilities',
      Component: Facilities,
    },
    {
      id: 'experience',
      label: 'Experience',
      Component: Experience,
    },
    {
      id: 'services',
      label: 'Services',
      Component: Services,
    },
    {
      id: 'skills',
      label: 'Skills',
      Component: Skills,
    },
    {
      id: 'tools',
      label: 'Tools',
      Component: Tools,
    },
  ]

  return (
    <>
      <Helmet>
        <script
          src="https://widget.cloudinary.com/v2.0/global/all.js"
          type="text/javascript"
        />
      </Helmet>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.root}>
            <PageHeader title="Coach Profile" />
            <FormActionBar
              includeCancel
              instructions={errors.length > 0 && 'Form has one or more errors'}
              error={errors.length > 0}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting || loading}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </FormActionBar>
            <Container className={classes.profile}>
              {/* persist form fields as long as the user has not successfully submitted the form */}
              <form onSubmit={handleSubmit(onSubmit)}>
                {!isEmpty(errors) && (
                  <ErrorDisplay errors={errors} touched={touched} />
                )}
                <Navigation />
                <div className={classes.formWrapper}>
                  {/* Profile Form Fields */}
                  {profileSectionsConfig.map(({ id, label, Component }) => (
                    <Fragment key={`${id}-fragment`}>
                      <div id={id} className={classes.idLink} />
                      <Card variant="outlined" className={classes.card}>
                        <CardHeader
                          title={label}
                          titleTypographyProps={{ variant: 'h6' }}
                          className={classes.header}
                          action={
                            id === 'facilities' &&
                            (!isEmpty(touched) ? (
                              <SaveDialog
                                handleSubmit={handleSubmit(onSubmit)}
                                trigger={
                                  <Button
                                    color="primary"
                                    variant="outlined"
                                    startIcon={
                                      !isMobile && <SettingsOutlinedIcon />
                                    }
                                    style={{ marginTop: '8px' }}
                                  >
                                    MANAGE {!isMobile && 'FACILITIES'}
                                  </Button>
                                }
                              />
                            ) : (
                              <Button
                                color="primary"
                                variant="outlined"
                                startIcon={
                                  !isMobile && <SettingsOutlinedIcon />
                                }
                                style={{ marginTop: '8px' }}
                                to="/pga-coach/settings/facilities"
                                component={Link}
                              >
                                MANAGE {!isMobile && 'FACILITIES'}
                              </Button>
                            ))
                          }
                        />
                        <CardContent className={classes.content}>
                          <Component formFields={formFields} onSubmit={onSubmit} />
                        </CardContent>
                      </Card>
                    </Fragment>
                  ))}
                </div>
                {!isEmpty(errors) && (
                  <ErrorDisplay errors={errors} touched={touched} />
                )}
              </form>
            </Container>
            <DialogAPIMessage
              message={apiResponseObject && apiResponseObject.message}
              success={apiResponseObject && apiResponseObject.success}
              open={openApiResponseDialog}
              handleClose={handleDialogClose}
              isCreate={false}
              customSlug={formFields.customSlug}
            />
          </div>
        </form>
      </FormProvider>
    </>
  )
}

ProfileForm.propTypes = {
  formFields: PropTypes.object,
}

export default ProfileForm
