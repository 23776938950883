import * as yup from 'yup'
import { inputPriceRegExp } from 'utils/regExp'
import {
  isRecurringPayment,
  AGE_GROUP_TAGS,
  MAX_TAGS,
} from 'utils/groupEventUtils'
import { buildDateTime } from 'lib/CustomLuxonUtils'
import { validateSessionOverlap } from '../../utils'

export const validationSchema = (
  coachFacilities = [],
  paymentAccounts = [],
  event = null,
) => {
  const { paymentRecurrenceSchedule } = event

  const priceValidation = yup
    .string()
    .matches(inputPriceRegExp, 'Please enter a valid price')
    .required('Please enter a price')

  const recurringPriceValidation = priceValidation.test(
    'isPositiveNumber',
    'Price must be a number greater than 0',
    value => {
      const numberValue = parseFloat(value)
      return !isNaN(numberValue) && numberValue > 0
    },
  )

  const sessionSchema = yup.object().shape({
    startDate: yup.date().required(),
    startTime: yup
      .date()
      .typeError('Start time must be a valid time')
      .label('Start Time')
      .required(),
    endTime: yup
      .date()
      .typeError('End time must be a valid time')
      .label('End Time')
      .required()
      .test(
        'endTimeAfterStartTime',
        'End time should be after start time',
        function (endTime) {
          const { startDate, startTime } = this.parent
          const startDateTime = buildDateTime(startDate, startTime)
          const endDateTime = buildDateTime(startDate, endTime)
          return endDateTime > startDateTime
        },
      ),
  })

  return yup.object().shape({
    title: yup
      .string()
      .min(1)
      .required(),
    maxAttendance: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        return String(originalValue).trim() === '' ? null : value
      }),
    unlisted: yup.boolean().required(),
    price: isRecurringPayment({ paymentRecurrenceSchedule })
      ? recurringPriceValidation
      : priceValidation,
    paymentAccountId: yup
      .string()
      .oneOf(['', ...paymentAccounts.map(pa => pa.id)])
      .nullable(),
    locationId: yup
      .string()
      .required()
      .oneOf(coachFacilities.map(f => f.id)),
    tags: yup
      .array()
      .required()
      .min(1)
      .test('max-tags', 'Maximum 5 tags allowed', function (value) {
        const nonAgeGroupTags = value.filter(
          tag => !AGE_GROUP_TAGS.includes(tag),
        )
        return nonAgeGroupTags.length <= MAX_TAGS
      }),
    ...(event.externalRegistrationUrl
      ? {
        externalRegistrationUrl: yup
          .string()
          .required('Please enter a URL')
          .url('Please enter a valid URL'),
      }
      : {}),
    featuredImageUrl: yup.string().required(),
    sessions: isRecurringPayment({ paymentRecurrenceSchedule })
      ? yup.array().notRequired()
      : yup
        .array()
        .of(sessionSchema)
        .min(1, 'Group events must have at least one date/time occurrence')
        .required()
        .test('validate', 'Sessions cannot overlap', value =>
          validateSessionOverlap(value),
        ),
    accessCode: yup.string().matches(/^[a-z0-9]+$/i, {
      message: 'Access codes must consist of numbers and letters only',
      excludeEmptyString: true.valueOf,
    }),
  })
}
